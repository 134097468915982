<template>
  <ul class="topbar-navigation">
    <router-link v-for="item in items" :key="item.path" :to="item.path">
      <li
        :class="{
          'topbar-navigation-item': true,
          'topbar-navigation-item--active': isActive(item),
        }"
      >
        {{ item.title }}
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "TopbarNavigation",
  props: {
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  methods: {
    isActive(item) {
      return item.routes.indexOf(this.$route.name) > -1;
    },
  },
};
</script>

<style>
.topbar-navigation {
  margin: 20px 0 20px 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  height: 37px;
}
.topbar-navigation a {
  text-decoration: none;
  margin-right: 20px;
}
.topbar-navigation a:last-child {
  margin-right: 0;
}
a .topbar-navigation-item {
  color: #fff;
  font-weight: bold;
  transition: color 0.1s ease-out;
}
a .topbar-navigation-item--active {
  color: #56bae0;
}
.topbar-navigation-item--active:after {
  content: "";
  display: block;
  width: 64px;
  height: 8px;
  background: url(../assets/topbar-navigation-active.svg);
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .topbar-navigation {
    margin: 0;
  }
}

@media (hover: hover) {
  a:hover .topbar-navigation-item {
    color: #a2e5ff;
  }
  a:hover .topbar-navigation-item--active:hover {
    color: #56bae0;
  }
}
</style>
