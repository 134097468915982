<template>
  <div id="app">
    <!-- <div v-show="loggedIn"> -->
      <row>
        <topbar
          :logged-in="loggedIn"
          :navigation-items="topbarNavigation.items"
          @log-in-click="logIn"
          @log-out-click="logOut"
        />
      </row>
    <!-- </div> -->
        <router-view />
    <div v-show="loggedIn">
      <row background-color="#014963">
        <app-footer />
      </row>
    </div> 
  </div>
</template>

<script>
import Row from "@/components/Row";
import Topbar from "@/components/Topbar";
import AppFooter from "@/components/AppFooter";

export default {
  name: "App",
  components: { Row, Topbar, AppFooter },
  data() {
    // Dummy data
    return {
      loggedIn: false,
      topbarNavigation: {
        items: [
          {
            title: "Overblik",
            routes: ["Overview", "TrackAdvanced"],
            path: "overview",
          },
          {
            title: "Opret ordre",
            routes: ["Create", "Label"],
            path: "create",
          },
          { title: "Stamdata", routes: ["Profile"], path: "profile" },
        ],
      },
      mytoken : ""
    };
  },
  // beforeDestroy() {
  //   console.log("Log out");
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("username");
  //   // this.loggedIn = false;
  //   localStorage.setItem("loggedIn", false);
  //   location.reload();
  // },
  created() {
    
    this.mytoken = localStorage.getItem("token");

      if(this.mytoken == null || this.mytoken == ""){
        this.loggedIn = JSON.parse(localStorage.getItem("loggedIn"));
        //this.$router.push({ name: 'Login'}); 
      }else{
        this.loggedIn = JSON.parse(localStorage.getItem("loggedIn"));
      }
  },
  
  methods: {
    logIn() {
      console.log("Log in");
      //this.loggedIn = true;
      this.$router.push({ name: 'Login'});
    },
    logOut() {
      console.log("Log out");
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("startDate");
      localStorage.removeItem("endDate");
      localStorage.removeItem("pageSize");
      localStorage.removeItem("currentPage");
      this.loggedIn = false;
      localStorage.setItem("loggedIn", false);
      location.reload();
    },
  },
};
</script>

<style>
  @import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&display=swap");
  * {
    box-sizing: border-box;
  }
  html,
  body {
    margin: 0;
    padding: 0;
  }
  html {
    background-color: #225268;
  }
  body {
    color: #ffffff;
  }
  #app {
    font-family: "Raleway", sans-serif;
  }
</style>
