<template>
  <button
    :class="{
      btn: true,
      'btn--outline': outline,
      'btn--icon': icon,
      'btn--center': center,
      'btn--right': right,
    }"
    :style="{ width: width }"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <img v-if="icon" class="btn__icon" :src="icon" />
    {{ label }}
  </button>
</template>

<script>
export default {
  name: "Btn",
  props: {
    icon: { type: String, default: null },
    label: { type: String, default: "" },
    width: { type: String, default: null },
    outline: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    center: { type: Boolean, default: false },
    right: { type: Boolean, default: false },
  },
};
</script>

<style>
.btn {
  position: relative;
  background-color: #f15726;
  color: #fff;
  height: 42px;
  padding: 0 20px;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 16px;
  cursor: pointer;
}
.btn:disabled {
  border: 1px solid #fff;
  background: transparent;
  cursor: default;
}
.btn.btn--icon {
  display: block;
  margin: 0 auto;
}
.btn.btn--center {
  display: block;
  margin: 0 auto;
}
.btn.btn--right {
  display: block;
  margin-left: auto;
}
.btn.btn--outline {
  background-color: transparent;
  border: 1px solid #fff;
}
.btn__icon {
  position: absolute;
  top: 8px;
  left: 18px;
  height: 26px;
}

@media (hover: hover) {
  .btn {
    transition: background-color 0.1s;
  }
  .btn:disabled.btn:hover {
    background: transparent;
  }
  .btn:hover {
    background-color: #ff8761;
  }
  .btn.btn--outline:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
</style>
