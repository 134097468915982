<template>
  <div class="row" :style="{ backgroundColor }">
    <div :class="{ 'row-content': true, 'row-content--scroll': scroll }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Row",
  props: {
    backgroundColor: {
      type: String,
      default: "transparent",
    },
    scroll: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.row-content {
  position: relative;
  padding: 16px;
  max-width: 1400px;
  margin: 0 auto;
}
.row-content.row-content--compact {
  padding: 10px 16px 10px 16px;
}
.row-content.row-content--scroll {
  overflow-x: scroll;
}
</style>
