import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/login",
        name: "Login",
        component: () =>
            import ("../views/Login.vue"),
    },
    {
        path: "/track",
        name: "Track",
        component: () =>
            import ("../views/Track.vue"),
    },
    {
        path: "/overview",
        name: "Overview",
        component: () =>
            import ("../views/Overview.vue"),
    },
    {
        path: "/trackadvanced",
        name: "TrackAdvanced",
        component: () =>
            import ("../views/TrackAdvanced.vue"),
    },
    {
        path: "/create",
        name: "Create",
        component: () =>
            import ("../views/Create.vue"),
    },
    {
        path: "/label",
        name: "Label",
        component: () =>
            import ("../views/Label.vue"),
    },
    {
        path: "/notfound",
        name: "NotFoundPage",
        component: () =>
            import ("../views/NotFoundPage.vue"),
    },
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

export default router;