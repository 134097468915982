import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from 'axios';
import VueAxios from 'vue-axios';
import i18n from '@/i18n';
import Vuelidate from 'vuelidate';
import * as VueGoogleMaps from "vue2-google-maps"

import moment from 'moment';

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY hh:mm')
    }
});

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyBISJpS6r8x0H3zp0el8_I_FnAfZCa_E_s",
        libraries: "places"
    }
});

Vue.use(VueAxios, axios);
Vue.use(Vuelidate);


new Vue({
    router,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
