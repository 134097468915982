<template>
  <div class="home"></div>
</template>

<script>
import axios from "axios";
export default {
  name: "Home",
  data() {
    // Dummy data
    return {
      mytoken: "",
    };
    
  },
  created(){
    this.mytoken = localStorage.getItem("token");
    if(this.mytoken == null || this.mytoken == ""){
      this.loggedIn = false;
      this.$router.push({ name: 'Login'}); 
      //location.reload()
    }else{
      this.mytoken = localStorage.getItem("token");
      
      var config = {
        method: "get",
        url: "https://engine.bftlogistik.dk:7000/partner/v1/checktoken/"+this.mytoken
      };
      axios(config)
      .then((response) => {
        if(response.data.token.status == true){
          this.loggedIn = true;
        }
      })
      .catch(function (error) {
        console.log(error);
        if(error.response.status == 404)
        {
          localStorage.removeItem("token");
          localStorage.removeItem("username");
          localStorage.removeItem("startDate");
          localStorage.removeItem("endDate");
          localStorage.removeItem("pageSize");
          localStorage.removeItem("currentPage");
          localStorage.setItem("loggedIn", false);
          location.reload();
        }
      });
    }
  },
};
</script>
